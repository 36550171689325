.newDatePickerClass .custom-datepicker {
  color: #ffffff !important;
  background-color: #2c2c2e !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  height: 40px !important;
  width: 160px !important;
  border-radius: 6px !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.rs-picker-toggle.custom-datepicker {
  background-color: #5c5c5c !important;
  color: #ffffff !important;
  border-radius: 6px !important;
  height: 40px !important;
  width: 160px !important;
  font-size: 1rem !important;
  font-weight: bold !important;
}

.newDatePickerClass .rs-icon.rs-picker-caret-icon {
  display: none;
}

.newDatePickerClass
  .custom-datepicker.custom-datepicker.rs-picker.rs-picker-date.rs-picker-subtle.rs-picker-toggle-wrapper.rs-picker-cleanable.rs-picker-block {
  border: 1px solid;
  border-color: #2c2c2e;
}

.newDatePickerClass
  .rs-input-group.rs-input-group-inside.rs-picker-input-group {
  background-color: #2c2c2e;
  height: 30px;
}

.newDatePickerClass
  .rs-picker-date
  > .rs-input-group.rs-input-group-inside
  .rs-input {
  background-color: #2c2c2e;
  color: white;
  font-weight: bold;
  font-size: large;
  padding-left: 0px;
}

.newDatePickerClass .rs-picker-date > .rs-input-group.rs-input-group-inside .rs-input::placeholder {
  background-color: #2c2c2e;
  font-size: 18px;
}

/* ================================================================ */

.newDateInputClass .custom-datepicker {
  color: #ffffff !important;
  background-color: #414142 !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  height: 40px !important;
  width: 100% !important;
  border-radius: 6px !important;
  padding: 6px 0px;
}

.newDateInputClass .rs-icon.rs-picker-caret-icon {
  color: #ccff00;
  width: 22px !important;
  height: 22px !important;
}

.newDateInputClass
  .custom-datepicker.custom-datepicker.rs-picker.rs-picker-date.rs-picker-subtle.rs-picker-toggle-wrapper.rs-picker-cleanable.rs-picker-block {
  border: 1px solid;
  border-color: #414142;
}

.newDateInputClass .rs-input-group.rs-input-group-inside.rs-picker-input-group {
  background-color: #414142;
  height: 30px;
}

.newDateInputClass
  .rs-picker-date
  > .rs-input-group.rs-input-group-inside
  .rs-input {
  background-color: #414142;
  color: white;
  font-weight: bold;
  font-size: large;
  padding-left: 0px;
}

.newDateInputClass .rs-picker-date > .rs-input-group.rs-input-group-inside .rs-input::placeholder {
  background-color: transparent;
  font-weight: 400;
  color: rgb(169, 169, 169);
}

.newDateInputClass .rs-input {
  background-color: #414142 !important;
}
/* ================================================================ */

.modalDatePicker .custom-datepicker {
  color: #ffffff !important;
  background-color: #1F2022 !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  height: 40px !important;
  width: 100% !important;
  border-radius: 6px !important;
  padding: 6px 0px !important;
}

.modalDatePicker .rs-icon.rs-picker-caret-icon {
  color: #ccff00;
  width: 22px !important;
  height: 22px !important;
}

.modalDatePicker
  .custom-datepicker.custom-datepicker.rs-picker.rs-picker-date.rs-picker-subtle.rs-picker-toggle-wrapper.rs-picker-cleanable.rs-picker-block {
  border: 1px solid;
  border-color: #414142;
}

.modalDatePicker .rs-input-group.rs-input-group-inside.rs-picker-input-group {
  background-color: #1F2022;
  height: 30px;
}

.modalDatePicker
  .rs-picker-date
  > .rs-input-group.rs-input-group-inside
  .rs-input {
  background-color: #1F2022;
  color: white;
  font-weight: bold;
  font-size: large;
  padding-left: 0px;
}

.modalDatePicker .rs-picker-date > .rs-input-group.rs-input-group-inside .rs-input::placeholder {
  background-color: transparent;
  font-weight: 400;
  color: rgb(169, 169, 169);
}

.modalDatePicker .rs-input {
  background-color: #1F2022 !important;
}
/* ================================================================ */

.detailDatePicker .custom-datepicker {
  color: #ffffff !important;
  background-color: #414142 !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  height: 30px !important;
  width: 100% !important;
  border-radius: 6px !important;
  padding: 0px 0px;
}

.detailDatePicker  .rs-picker-date
> .rs-input-group.rs-input-group-inside
.rs-input {
background-color: #414142;
color: white;
font-weight: normal;
font-size: medium;
padding-left: 0px;
}

.rs-picker-toggle.custom-datepicker .rs-picker-toggle-placeholder {
  color: #ffffff !important;
}

.rs-stack {
  background-color: #5c5c5c;
  color: white;
  font-weight: 600;
  z-index: 1080;
}

.rs-anim-fade.rs-anim-in.rs-picker-popup-date.rs-picker-popup.placement-bottom-start {
  z-index: 1080;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #ccff00;
  color: black;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell-content {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.rs-calendar-month-dropdown-cell-content {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.rs-picker-popup .rs-calendar {
  height: 310px;
}

.rs-calendar-table-cell-content:hover {
  color: black;
}

.rs-calendar-table-cell .rs-calendar-table-cell-is-today:hover {
  color: white;
}

.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  color: white;
}

.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell-selected:hover
  .rs-calendar-table-cell-content {
  background-color: #ccff00 !important;
  color: black !important;
}

.rs-calendar-body {
  background-color: #5c5c5c;
}

.rs-icon {
  background-color: #5c5c5c;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: none !important;
}

.rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content {
  color: #a3a3a3;
}

.rs-input-group.rs-input-group-inside.rs-picker-input-group:focus-visible {
  outline: none !important;
}

.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:not(.rs-input-group-disabled):hover {
  border-color: transparent !important;
}

.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
  outline: none !important;
}

.rs-calendar-month-dropdown-row-wrapper {
  background-color: #5c5c5c;
}

.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  background-color: #e3fb85;
  color: black;
}

.rs-calendar-btn-close {
  background-color: #5c5c5c;
}

.rs-btn.rs-btn-primary.rs-btn-sm {
  background-color: #ccff00;
  color: black;
  border: 1px solid;
  border-color: #5c5c5c;
}

.rs-picker-clean.rs-btn-close {
  color: #ccff00;
}

.rs-btn-close:focus,
.rs-btn-close:hover {
  color: #ccff00;
}

.rs-input-group.rs-input-group-inside .rs-input-group-addon {
  padding: 0px !important;
}

.rs-icon {
  background-color: transparent !important;
  width: 22px !important;
  height: 22px !important;
}

.rs-icon:hover {
  color: #ccff00;
}

.rs-btn-close:focus svg path,
.rs-btn-close:hover svg path {
  stroke: #ccff00 !important;
}

.rs-calendar-header-title.rs-calendar-header-title-date.rs-btn.rs-btn-subtle.rs-btn-xs {
  color: white;
  background-color: #5c5c5c;
  font-size: large;
  font-weight: 700;
  padding-bottom: 4px;
}

.rs-calendar-month-dropdown-year {
  color: #a8a8ab;
}

.rs-calendar-table-header-cell-content {
  color: white;
  font-size: medium;
  font-weight: 800;
}

.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs:hover {
  background-color: transparent;
  color: inherit;
  cursor: default;
  box-shadow: none;
}

.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs {
  color: white;
}

.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs:hover {
  background-color: transparent;
  color: inherit;
  cursor: default;
  box-shadow: none;
}

.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs {
  color: white;
}

.rs-calendar-header-month-toolbar {
  margin: 8px 0px 8px 0px !important;
}

.rs-calendar-table-header-cell {
  padding-bottom: 8px;
}

.rs-calendar-btn-close {
  height: 28px;
}

.rs-calendar-month-dropdown {
  top: 55px;
}

.rs-calendar-month-dropdown {
  background-color: #5c5c5c;
}

.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs,
.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs {
    background-color: transparent !important; 
}

.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs:focus,
.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs:focus,
.rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs:active,
.rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-xs:active {
    background-color: transparent !important; 
    box-shadow: none; 
}



.rs-icon {
  width: 22px;
  height: 22px;
}

.rs-btn-icon.rs-btn-xs {
  padding: 0px;
}

.rs-btn {
  border-radius: 0px;
}
