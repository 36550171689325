@media screen and (max-width: 1930px) {
    .attendanceRespoClass {
        max-width: 1400px !important;
    }
}

@media screen and (max-width: 1750px) {
    .attendanceRespoClass {
        max-width: 1200px !important;
    }
}

@media screen and (max-width: 1550px) {
    .attendanceRespoClass {
        max-width: 1000px !important;
    }
}

@media screen and (max-width: 1350px) {
    .attendanceRespoClass {
        max-width: 900px !important;
    }
}

@media screen and (max-width: 1230px) {
    .attendanceRespoClass {
        max-width: 800px !important;
    }
}

@media screen and (max-width: 1110px) {
    .attendanceRespoClass {
        max-width: 1000px !important;
    }
}

