.modalBoxClass .modal-content {
  border-radius: 12px;
  width: 1000px;
}

.modalWidthClass .modal-content {
  border-radius: 12px;
  width: 1000px;
}

.modalWidthClass .modal-dialog {
  margin-top: 150px !important;
  margin-left: auto !important;
  margin-right: 50% !important;
}

.modalBoxClass .modal-body {
  border-radius: 8px;
}

.modalBoxClass .modal-dialog {
  margin-top: 150px !important;
  margin-left: auto !important;
  margin-right: 44% !important;
}

.classForMargin .modal-dialog {
  margin-top: 100px !important;
}

input:focus {
  outline: none;
}

textarea:focus {
  outline: none;
}

.checkboxClass {
  width: 50px !important;
  height: 50px !important;
}

.holdingClass .input-group-text {
  align-items: start !important;
  padding: 0px !important;
}

.checkboxClass .input-group-text {
  background-color: transparent;
  border: none;
  width: 50px !important;
  height: 50px !important;
}

.checkboxClass .input-group-text input {
  background-color: #505050 !important;
  border: 0.5px solid #cecece80;
}
.paddingManage .input-group-text {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.paddingManage {
  width: 45px !important;
}

.memoInputField {
  border: 1px solid #4e4e4e;
  background-color: transparent;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  padding: 0px 10px;
}

.accordion {
  --bs-accordion-bg: #353638 !important;
  --bs-accordion-border-color: #353638 !important;
  --bs-accordion-active-bg: #353638 !important;
  --bs-accordion-btn-padding-y: 0px !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../assests/icons/mingcute--down-line.svg") !important;
  background-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.accordion-button::after {
  background-image: url("../assests/icons/mingcute--down-line.svg") !important;
  background-size: 30px !important;
  width: 30px !important;
  height: 30px !important;
}

.manageActive .active,
.manageActive .active p {
  color: #fff !important;
}

.manageActive .hideClass {
  display: none;
}

.manageActive .active .activeClass {
  display: none;
}

.manageActive .active .hideClass {
  display: block;
}

.subOptionClass .active {
  background-color: #ccff00;
  border-radius: 28px;
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  width: auto;
}

.gymSettngOptionClass .active {
  background-color: transparent !important;
  border-radius: 28px;
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  width: auto;
}

.subOptionClass .active p {
  color: #000000 !important;
}

.gymSettngOptionClass .active p {
  color: #ccff00 !important;
}

.datePickerBorder {
  border: 1px solid #505050;
  background-color: #000000;
}

.genderOption input {
  accent-color: #ccff00;
}

.loaderClass {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: #0000006b;
  z-index: 9999999;
}

.loaderClass .loader-icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trainerList li::marker {
  margin-left: 1px !important;
}

.testClass::before {
  content: url(./icons/triangle.svg) !important;
  position: absolute;
  top: -20px;
  right: 50px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 60px !important;
}

.scrollingClass::-webkit-scrollbar {
  height: 4px !important;
}

.scrollingClass::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 15px #8e8c8c;
}

.scrollingClass::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #474747;
  border-radius: 10px;
}

.suggestiontableClass .table > :not(caption) > * > * {
  background-color: transparent !important;
  border: 1px solid #444444;
}

.classForHideBorder .table > :not(caption) > * > * {
  border: none !important;
  background-color: transparent !important;
}

.newActiveBorder {
  border: 2px solid #ccff00;
}

.suggestiontableClass .table {
  border: 1px solid #444444;
}

.suggestiontableClass .table thead th {
  padding: 15px 0px;
  border: 1px solid #444444;
}

.staffListClass .table > :not(caption) > * > * {
  background-color: transparent;
  border: 1px solid #444444;
  border-top: none;
  border-bottom: none;
}

.staffListClass .table {
  border: 1px solid #444444;
}

.staffListClass .table > thead,
.staffListClass .table > tbody {
  vertical-align: middle !important;
}

.staffListClass .table thead th {
  padding: 5px 0px;
  border: 1px solid #444444;
  border-top: none;
  border-bottom: none;
}

.titleScrollClass::-webkit-scrollbar {
  height: 1px !important;
}

.titleScrollClass::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 15px #8e8c8c;
}

.titleScrollClass::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #474747;
  border-radius: 10px;
}

.isEditedClass {
  background-color: #f6eb614d !important;
  border: 2px solid #f6eb6180 !important;
}

.textOverflowClass {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.borderClassMember:hover {
  /* border: 0.1px solid #CCFF00; */
  outline: 0.5px solid #ccff00;
  outline-offset: -3px;
}

.shadowClass {
  box-shadow: 0px 0px 25px 4px #ccff006b;
}

.shadowHoverClass:hover {
  box-shadow: 0px 0px 25px 4px #ccff006b;
}

.p-mention-panel.p-component.p-connected-overlay-enter-done ul li:first-child {
  background: #5c5c5c;
  max-width: 33%;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #747474;
}
.p-mention-panel.p-component.p-connected-overlay-enter-done ul li:last-child {
  background: #5c5c5c;
  max-width: 33%;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #747474;
}

.react-tooltip-arrow.core-styles-module_arrow__cvMwQ.styles-module_arrow__K0L3T.arrowClass {
  left: 25px !important;
  top: -8px !important;
  width: 14px !important;
  height: 20px !important;
}

/* ======================================================================================== */

.remeberMeClass {
  width: 35px !important;
}

.rememberMeClass .form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("../assests//icons/right-tick.svg") !important;
  background-size: 15px !important;
}

.attendnaceModalClass .modal-content {
  width: 660px !important;
}

.attendnaceModalClass .modal-dialog {
  margin-top: 140px !important;
  margin-left: auto !important;
  margin-right: 37.5% !important;
}

@media (max-width: 1000px) {
  .modalBoxClass .modal-content {
    width: auto !important;
  }

  .modalBoxClass .modal-dialog {
    margin-top: 100px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
