.kakaoClass {
    border: 0.5px solid #CCFF00 !important;
}


.logibBtnOver:hover {
    border: 0.5px solid #CCFF00;
    background-color: transparent !important;
}

/* =========Slider classes=========== */

.slick-initialized .slick-slide {
    width: 300px !important;
}

.slick-dots li.slick-active button:before, .slick-dots li button:before {
    color: #CCFF00 !important;
}

.slick-dots {
    bottom: -35px !important;
}