
  .dateInputClass .react-datepicker-wrapper {
    width: 100% !important;
  }

  .dateInputClass .react-datepicker__view-calendar-icon input {
    padding-left: 0px !important;
  }

  .dateInputClass .react-datepicker, .dateInputClass .react-datepicker__header .dateInputClass .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .dateInputClass .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    background-color: #5C5C5C;
    color: #fff !important;
    border: none;

  }

  .dateInputClass .react-datepicker-popper {
    z-index: 999 !important;
  }

  .dateInputClass .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    background-color: #5C5C5C;
    color: #fff !important;
    padding: 20px 0px;
    font-size: 16px !important;
  }

  .dateInputClass .react-datepicker__navigation--previous, .dateInputClass .react-datepicker__navigation--next {
    top: 20px;
  }

  .dateInputClass .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle, .dateInputClass .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
    fill: #5C5C5C !important;
    color: #5C5C5C !important;
    stroke: #5C5C5C !important;
}

  
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 2.7rem !important;
  line-height: 2.7rem !important;
  text-align: center;
  margin: 0.166rem;
  font-size: 16px !important;
}

  .dateInputClass .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #fff !important;
  }
  .dateInputClass .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
    color: #a4a0a0 !important;
  }

  .dateInputClass .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    color: #444444 !important;
    background-color: #CCFF00 !important;
    border-radius: 100%;
  }

  .dateInputClass .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    color: #444444 !important;
    background-color: #CCFF00 !important;
    border-radius: 100%;
  }

  .tableClass {
    border: 1px solid;
    border-color: #444444 !important;
  }

  .tableClass th{
    padding: 15px 10px !important;
  }

  .inputErrorClass input, .inputErrorClass .errorBorder {
    border: 1px solid #F87171;
  }

  .inputErrorClass input::placeholder, .inputErrorClass .spanClass, .inputErrorClass .errorBorder svg{
    color: #F87171 !important;
  }

  .errorClass {
    border: 1px solid #F87171;
  }

  .errorClass input::placeholder{
    color: #F87171 !important;
  }

  .placeholderClass input::placeholder{
    color: #A1A1AA;
    font-weight: 700;
  }


/* ==============Pagination CSS=================== */
.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color:#fff !important;
  --bs-pagination-bg: #353638 !important;
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: none !important;
  --bs-pagination-active-color: #000 !important;
  --bs-pagination-active-bg: #CCFF00 !important;
  --bs-pagination-active-border-color: #CCFF00 !important;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  justify-content: center !important;
  padding-left: 0;
  list-style: none;
}

.page-link {
  font-weight: 400;
  font-size: 14px !important;
  border-color: #444444 !important;
}

.disabled>.page-link, .page-link.disabled,  .page-link:focus {
  font-weight: 700;
  background-color: #353638 !important;
  color: #fff !important;
}

.page-link:hover {
  font-weight: 800;
  background-color: #CCFF00 !important;
  color: #000 !important;
}

.activeBorder {
  border: 1px solid #CCFF00
}

.numberFieldClass input::-webkit-outer-spin-button,
.numberFieldClass input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("../../../assests//icons/right-tick.svg") !important;
  background-size: 22px !important;
}

.lockerNumberField {
  border: 1px solid #4E4E4E;
}

.uploadImageClass {
  width: 185px;
  height: 185px;
}

@media screen and (max-width: 1900px) {
    .memberListClass {
        width: 1460px !important;
    }
}
 
@media screen and (max-width: 1550px) and (min-width: 1400px)  {
  .uploadImageClass {
    width: 165px !important;
    height: 165px !important;
  }
}
